import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

const Navbar = ({ textColour }) => {
  const [isBlurred, setIsBlurred] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 500) { // Adjust this value based on when you want the blur to start
        setIsBlurred(true);
      } else {
        setIsBlurred(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`fixed font-luxjost md:flex p-4 z-20 w-full transition-all duration-300 ${isBlurred ? 'backdrop-blur bg-orange-100/50 text-orange' : 'bg-transparent text-white backdrop-blur'}`}>
      <div className="flex justify-between items-center w-full md:w-auto">
        <Link to="/" className="text-2xl text-orange-600 font-bold">
          Sky-Swift <span className="block text-2xl text-orange-600">Logistics</span>
        </Link>
        <button 
          className="md:hidden text-orange-400 focus:outline-none" 
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={!isMenuOpen ? 'M4 6h16M4 12h16m-7 6h7' : 'M6 18L18 6M6 6l12 12'}></path>
          </svg>
        </button>
      </div>
      <ul className={`md:flex font-fredoka md:ml-10 md:space-x-10 font-semibold text-xl mt-4 md:mt-0 ${isMenuOpen ? 'block' : 'hidden'} md:block`}>
        <li className='hover:text-orange-400 duration-500 cursor-pointer'><Link to="/">Ship</Link></li>
        <li className='hover:text-orange-400 duration-500 cursor-pointer'><Link to="/">Track</Link></li>
        <li className='hover:text-orange-400 duration-500 cursor-pointer'><Link to="/">Customer Service</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;
