import React from "react";
import process1 from "../assets/images/process1.png";
import process2 from "../assets/images/process2.png";
import process3 from "../assets/images/process3.png";
import process4 from "../assets/images/process4.png";
import ReactCountryFlag from "react-country-flag";
import { useState } from "react";
import ReactFlagsSelect from "react-flags-select";

export default function StartShippingPage() {
  const [selected, setSelected] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="pt-32">
      <section className="flex flex-col space-y-10 font-fredoka items-center justify-center px-4 md:px-0">
        <p className="text-4xl md:text-6xl text-orange-600 font-semi">START SHIPPING</p>
        <div className="text-center text-lg md:text-2xl">
          <p>
            Whether it's a single envelope or a multi-pallet shipment, we deliver your
          </p>
          <p>
            shipments across the globe. Get your custom quote and shipping plan in
          </p>
          <p>
            minutes. Provide your details below, choose your options, and book with ease.
          </p>
          <p>Your cargo our priority</p>
        </div>
        <div className="relative w-full md:w-[1000px] h-[600px] md:h-96 space-x-0 md:space-x-10 flex flex-col md:flex-row items-center md:items-start">
          <div className="relative flex-shrink-0 w-40 md:w-1/4">
            <img src={process1} alt="process1" className="absolute left-0 top-0 w-full" />
          </div>
          <div className="relative flex-shrink-0 w-40 md:w-1/4 mt-20 md:mt-0">
            <img src={process2} alt="process2" className="absolute left-0 md:left-[250px] top-[150px] md:top-0 w-full" />
          </div>
          <div className="relative flex-shrink-0 w-40 md:w-1/4 mt-20 md:mt-0">
            <img src={process3} alt="process3" className="absolute left-0 md:left-[500px] top-0 w-full" />
          </div>
          <div className="relative flex-shrink-0 w-40 md:w-1/4 mt-20 md:mt-0">
            <img src={process4} alt="process4" className="absolute left-0 md:left-[750px] top-[150px] md:top-0 w-full" />
          </div>
          <div className="absolute text-center top-[150px] md:left-0 md:right-auto left-0 md:left-auto">
            <p>Enter Origin and</p>
            <p>Destination</p>
          </div>
          <div className="absolute text-center top-[300px] md:left-[230px] left-0 md:left-auto">
            <p>Describe your</p>
            <p>Shipment</p>
          </div>
          <div className="absolute text-center top-[150px] md:left-[470px] left-0 md:left-auto">
            <p>Get delivery</p>
            <p>Prices</p>
          </div>
          <div className="absolute text-center top-[300px] md:left-[750px] left-0 md:left-auto">
            <p>Continue</p>
            <p>To booking</p>
          </div>
          <div className="absolute left-44 top-20">
            <div className="dashed-line"></div>
          </div>
          <div className="absolute left-[400px] top-16">
            <div className="dashed-line2"></div>
          </div>
          <div className="absolute left-[650px] top-[115px]">
            <div className="dashed-line3"></div>
          </div>
        </div>

        <div className="w-full md:w-[900px] h-auto md:h-[480px] p-4 md:p-0 flex flex-col border rounded-md border-t-orange-600 shadow-2xl border-t-8 relative">
          <div className="flex flex-col md:flex-row self-center space-y-4 md:space-y-0 md:space-x-10">
            <div>
              <div className="flex flex-col m-5">
                <p className="text-lg md:text-2xl font-semibold text-black">From</p>
                <div className="flex flex-col space-y-4 md:space-y-9">
                  <ReactFlagsSelect
                    selected={selected}
                    className="w-full md:w-80 border-black border h-11 rounded"
                    onSelect={(code) => setSelected(code)}
                  />
                  <label className="border border-black w-full md:w-80 h-11 rounded p-2">
                    <input
                      type="radio"
                      value="Drop-off at Officies"
                      checked={selectedOption === "Drop-off at Officies"}
                      onChange={handleRadioChange}
                      className="form-radio text-blue-600"
                    />
                    <span className="text-lg"> Drop-off at Officies</span>
                  </label>
                </div>
                <p className="text-lg md:text-2xl font-semibold text-black mt-5">To</p>
                <div className="flex flex-col space-y-4 md:space-y-9">
                  <ReactFlagsSelect
                    selected={selected}
                    className="w-full md:w-80 border-black border h-11 rounded"
                    onSelect={(code) => setSelected(code)}
                  />
                  <label className="border border-black w-full md:w-80 h-11 rounded p-2">
                    <input
                      type="radio"
                      value="Pickup at our Officies"
                      checked={selectedOption === "Pickup at our Officies"}
                      onChange={handleRadioChange}
                      className="form-radio text-blue-600"
                    />
                    <span className="text-lg"> Pickup at our Officies</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="flex flex-col space-y-14 mt-10 md:mt-[50px]">
              <div className="flex flex-col space-y-4 md:space-y-9">
                <input
                  placeholder="City(Optional)"
                  className="w-full md:w-80 pl-3 h-11 border border-black rounded"
                />
                <label className="border border-black w-full md:w-80 h-11 rounded p-2">
                  <input
                    type="radio"
                    value="Pickup at our Officies"
                    checked={selectedOption === "Pickup at our Officies"}
                    onChange={handleRadioChange}
                    className="form-radio text-blue-600"
                  />
                  <span className="text-lg"> Pickup at our Officies</span>
                </label>
              </div>
              <div className="flex flex-col space-y-4 md:space-y-9">
                <input
                  placeholder="City(Optional)"
                  className="pl-3 w-full md:w-80 h-11 border border-black rounded"
                />
                <label className="border border-black w-full md:w-80 h-11 rounded p-2">
                  <input
                    type="radio"
                    value="Pickup at our Officies"
                    checked={selectedOption === "Pickup at our Officies"}
                    onChange={handleRadioChange}
                    className="form-radio text-blue-600"
                  />
                  <span className="text-lg"> Pickup at our Officies</span>
                </label>
              </div>
            </div>
          </div>

          <button className="self-center mt-4 text-2xl font-semibold text-black h-10 w-64 rounded-sm bg-orange-400">
            Describe Shipment
          </button>
        </div>
      </section>
      <section className="w-full p-8 md:p-64 bg-orange-500 mt-20 flex flex-col items-center justify-center">
        <p>SHIPMENT DETAILS</p>
        <div className="w-full md:w-[1000px] h-[600px] bg-white self-center"></div>
      </section>
    </div>
  );
}
