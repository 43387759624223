// src/pages/HomePage.js
import React from "react";
import ship from "../assets/images/ship.jpg";
import dhl_plane from "../assets/images/dhl_plane.jpg";
import mitchell_warehouse from "../assets/images/mitchel_warehouse.png";
import quote from "../assets/icons/quote.png";
import shipbox from "../assets/icons/shipbox.png";
import tracking from "../assets/icons/tracking.png";
import crane from "../assets/icons/crane.png";
import handshake from "../assets/icons/handshake.png";
import exchange from "../assets/icons/exchange.png";
import walk from "../assets/icons/walk.png";
import handshake1 from "../assets/icons/handshake1.png";
import crane2 from "../assets/images/crane.png";
import shipping from "../assets/icons/shipping.png";
import containers from "../assets/icons/containers.png";
import serviceIcon1 from "../assets/icons/service-icon-1.png";
import serviceIcon2 from "../assets/icons/service-icon-2.png";
import serviceIcon3 from "../assets/icons/service-icon-3.png";
import serviceIcon4 from "../assets/icons/service-icon.png";
import worldwide from "../assets/images/worldwide.jpg";
import visa from "../assets/logos/visa_logo.png";
import i_m from "../assets/logos/im.png";
import mastercard from "../assets/logos/mastercard.png";
import dhl from "../assets/logos/dhl.png";
import uba from "../assets/logos/uba_bank_logo.png";
import sbm from "../assets/logos/sbm_logo.png";
import ipay from "../assets/logos/ipay.png";
import stanbic from "../assets/logos/stanbic.png";
import equity from "../assets/logos/equity_bank_logo.png";
import mitchell from "../assets/logos/mitchell.png";
import truck from "../assets/icons/truck.png";
import number1 from "../assets/icons/number.png";
import number2 from "../assets/icons/number2.png";
import number3 from "../assets/icons/number3.png";
import number123 from "../assets/icons/number123.png";
import reception from "../assets/icons/receiption.png";
import delivery from "../assets/icons/delivery.png";
import shipping_package from "../assets/icons/shipping_package.png";
import route from "../assets/icons/route.png";


const HomePage = () => {
  return (
    <div className="font-fredoka flex flex-col w-full">
      <div className=" font-fredoka mb-[100px] pt-[600px] flex items-center justify-center h-screen bg-cover  bg-center md:justify-start md:pt-0"
        style={{ backgroundImage: `url(${ship})` }}>
        <div className="flex flex-col justify-center space-y-3">
          <div className=" md:pl-14 md:w-full w-80 m-auto">
            <h1 className="text-4xl md:text-8xl font-bold text-white">Welcome to</h1>
            <h2 className="text-3xl md:text-7xl font-bold text-orange-700">Sky-Swift Logistics</h2>
            <p className="text-white text-xl md:text-3xl  md:text-left">
              Global Logistics Expertise you can count on
            </p>            
            <p className="text-white text-xl md:text-3xl  md:text-left">
               for International Shipping and more
            </p>
          </div>
          <div className="md:absolute md:w-full md:mx-auto flex-wrap md:pl-0 pt-14 flex pb-[200px] md:pb-[650px]   items-center justify-center  md:scale-y-[-1]">
            <div className="text-center h-40  bg-gray-100 w-60 p-1 md:w-56 md:scale-y-[-1] shadow-xl border border-orange-400 rounded-lg m-2">
              <img src={quote} alt="ship" className="h-14 w-14 ml-20 md:ml-24 mt-2" />
              <p className="font-bold text-center">Get a quote</p>
              <p className="font-thin flex flex-wrap">Estimate how much it will cost to ship your package</p>
            </div>
            <div className="bg-gray-100 w-60 md:w-56 pb-5  md:scale-y-[-1] shadow-xl border-orange-400 border rounded-lg ">
              <img src={tracking} alt="shipbox" className="h-[70px] w-[70px] ml-20 md:ml-20 mt-4" />
              <p className="font-bold text-center">Tracking</p>
              <p className="text-center font-thin">Track your package's delivery progress</p>
            </div>
            <div className="bg-gray-100 h-40 w-60 md:w-56 md:scale-y-[-1] border shadow-xl border-orange-400 rounded-lg m-2">
              <img src={shipbox} alt="shiptracking" className="h-16 w-16 ml-20 md:ml-24 mt-2" />
              <p className="font-bold text-center">Ship Now</p>
              <p className="text-center font-thin">Get your package on the road</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col  md:flex-row mt-[100px] md:mt-0 border-2 justify-center items-center rounded border-orange-400  mx-10 md:mx-auto md:w-[500px]">
        <input
          type="text"
          placeholder="Enter your tracking number"
          className="h-12 w-full md:w-[390px] pl-2  md:mb-0"
        />
        <button className="h-12 w-full md:w-28 shadow-2xl font-bold bg-orange-400 text-white border md:ml-2">
          Track
        </button>
      </div>

      <div className="flex flex-col  mt-10">
        <div className="w-full flex items-center justify-between bg-slate-200 h-20">
          <h2 className="text-xl md:text-5xl md:mx-auto font-medium tracking-wider text-blue-950 mx-auto">
            OUR SERVICES
          </h2>
          <div className="flex space-x-5 mt-2">
            <img className="md:h-14 w-[40px]" src={containers} alt="containers" />
            <img className="md:h-14 w-[40px]" src={crane} alt="crane" />
            <img className="md:h-14 w-[40px]" src={shipping} alt="shipping" />
          </div>
        </div>

        <div className="md:relative w-full mt-3  md:h-[600px] bg-cover bg-center" style={{ backgroundImage: `url(${worldwide})` }}>
          <img src={crane2} className=" md:mx-auto "/>
          <div className="md:absolute inset-0  flex flex-col items-center justify-center md:flex-row space-y-10 md:space-y-0 md:space-x-10">
            <div className="md:absolute md:left-0 md:top-0  md:ml-10 text-start h-32 w-[350px] md:w-96">
              <img src={serviceIcon4} alt="ship" className="h-14 w-14 p-2 z-50" />
              <div className="ml-9 bg-white shadow-lg rounded-lg p-3">
                <p className="font-bold text-orange-400 tracking-wide text-xl md:text-2xl">
                  Real-Time Tracking
                </p>
                <p className="font-thin">
                  Stay connected to your cargo's journey
                </p>
                <p className="font-thin">with our advanced tracking system</p>
              </div>
            </div>

            <div className="md:absolute md:right-0 md:top-0  md:mr-10 text-start h-32 w-[350px] md:w-96">
              <img src={serviceIcon3} alt="ship" className="h-14 w-14 p-2 z-50" />
              <div className="ml-9 bg-white shadow-lg rounded-lg p-3">
                <p className="font-bold text-orange-400 tracking-wide text-xl md:text-2xl">
                  Secure Shipping
                </p>
                <p className="font-thin">
                  Our secure handling and vigilant tracking ensure your cargo is
                  protected through its journey
                </p>
              </div>
            </div>
            <div className="md:absolute md:right-0 md:bottom-20  md:mb-10  md:mr-10 text-start h-32 w-[350px]  md:w-96">
              <img src={serviceIcon2} alt="ship" className="h-14 w-14 p-2 z-50" />
              <div className="ml-9 bg-white shadow-lg rounded-lg p-3">
                <p className="font-bold text-orange-400 tracking-wide text-xl md:text-2xl">
                  Packaging
                </p>
                <p className="font-thin">
                  We provide customized packing ensuring your items are safe
                  during transit
                </p>
              </div>
            </div>

            <div className="md:absolute md:left-0 md:bottom-20 mb-20  md:mb-10  md:ml-10 text-start h-32 w-[350px]  md:w-96">
              <img src={serviceIcon1} alt="ship" className="h-14 w-14 p-2 z-50" />
              <div className="ml-9 bg-white shadow-lg rounded-lg p-3">
                <p className="font-bold text-orange-400 tracking-wide text-xl md:text-2xl">
                  Reliable Customer Service
                </p>
                <p className="font-thin">
                  Our dedicated team is always ready to assist you with any
                  queries
                </p>
              </div>
            </div>

          </div>

        </div>
      </div>
      <div>
      <div className="flex flex-col md:flex-row-reverse w-full justify-end md:h-screen md:items-center md:mt-0 mt-[70px]" style={{ backgroundImage: `url(${dhl_plane})`, backgroundSize: 'cover' }}>
        <div className="flex flex-col w-full md:w-[650px]  md:ml-auto md:items-center bg-white bg-opacity-30 md:backdrop-blur-lg rounded-lg  p-5 md:p-10">
          <p className="text-3xl font-bold text-red-700 underline text-center md:text-left">DHL Official Agent</p>
          <p className="text-lg md:text-3xl font-semibold text-center md:text-left">Our air and ocean freight forwarding services are powered by a partnership with DHL Global Forwarding, ensuring your cargo reaches its destination efficiently and on time.</p>
          <a href="https://dhl.com" target="_blank">
          <button className="flex justify-center items-center mt-5 h-10 w-40 shadow-2xl font-xl font-bold text-Track border text-red-700 mx-auto md:mx-0 md:ml-0 md:mt-5">
            Learn More
          </button>
          </a>
        </div>
      </div>
      <div className="flex flex-col  md:flex-row-reverse w-full md:h-[650px]  md:items-center" style={{ backgroundImage: `url(${mitchell_warehouse})`, backgroundSize: 'cover' }}>
        <div className="flex flex-col w-full md:w-[700px] bg-white bg-opacity-30  md:items-center md:backdrop-blur-lg rounded-lg md:mr-10 p-5 md:p-5">
          <p className="text-3xl font-bold text-blue-700 underline text-center md:text-left">Mitchell Cotts Super Agent</p>
          <p className="text-lg font-semibold md:text-3xl text-center md:text-left">We have strong working relationships with Mitchell Cotts, enabling us to offer flexible routing and multiple ocean transportation options containers.</p>
          <a href="https://mitchellcottsgroup.com" target="_blank">
          <button className="flex justify-center items-center mt-5 h-10 w-40 shadow-2xl font-xl font-bold border text-Track text-blue-700 mx-auto md:mx-0 md:ml-0 md:mt-5">
            Learn More
          </button>
          </a>
        </div>
      </div>
      </div>
      <div className="w-full md:px-auto  ">
          <div className="w-full flex items-center justify-between bg-slate-200 h-20">
            <h2 className="text-xl md:text-5xl md:mx-auto font-medium tracking-wider text-blue-950 mx-auto">
              THE PROCESS
            </h2>
            <div className="flex space-x-5 mt-2">
              <img className="md:h-14 w-[40px]" src={walk} alt="containers" />
              <img className="md:h-14 w-[40px]" src={route} alt="crane" />
              <img className="md:h-14 w-[40px]" src={number123} alt="shipping" />
            </div>
          </div>
      </div>
        <div className="m-5 mb-32 ">
          <div className="text-6xl font-medium">
            <p>Our process can be </p>
            <p>simply described in </p>
            <p className="text-orange-400">3 easy steps </p>
          </div>
          <div className="flex flex-col space-y-10 md:flex md:flex-row  md:justify-between md:mx-32 md:mt-10">

            <div className="bg-orange-600 mx-auto p-5  rounded-lg ">
              <div className="flex flex-col justify-center items-center relative">
                <img className="h-64" src={delivery} alt="step1" />
                <img className="h-20 absolute top-52" src={number1} alt="step1 " />
                <div className="mt-9 flex flex-col items-center  ">
                  <p className="text-2xl font-bold">Receive the package </p>
                  <p className="text-2xl ">Your package arrives</p>
                  <p className="text-2xl ">safely at our secure</p>
                  <p className="text-2xl ">facility</p>
                </div>
              </div>
            </div>
            <div className="bg-orange-600 mx-auto p-5  rounded-lg ">
              <div className="flex flex-col justify-center items-center relative">
                <img className="h-64" src={shipping_package} alt="step1" />
                <img className="h-20 absolute top-52" src={number2} alt="step1 " />
                <div className="mt-9 flex flex-col items-center  ">
                  <p className="text-2xl font-bold">Shipping the package </p>
                  <p className="text-2xl ">efficiently using the</p>
                  <p className="text-2xl ">optimal route</p>
                </div>
              </div>
            </div>
            <div className="bg-orange-600 mx-auto p-5  rounded-lg ">
              <div className="flex flex-col justify-center items-center relative">
                <img className="h-64" src={reception} alt="step1" />
                <img className="h-20 absolute top-52" src={number3} alt="step1 " />
                <div className="mt-9 flex flex-col items-center  ">
                  <p className="text-2xl font-bold">Delivering of Package</p>
                  <p className="text-2xl ">We confirm successful </p>
                  <p className="text-2xl ">deliver to your recipient </p>
                </div>
              </div>
              {/* <img className="absolute right-10 bottom-10  h-72" src={truck} alt="step1" /> */}
            </div>
          </div>
      </div>
      <div className="w-full  ">
          <div className="w-full flex items-center justify-between bg-slate-200 h-20">
          <h2 className="text-2xl md:text-5xl md:mx-auto font-medium tracking-wider text-blue-950 mx-auto">
            OUR PARTNERS
          </h2>
          <div className="flex space-x-5 mt-2">
            <img className="md:h-14 w-[40px]" src={handshake} alt="containers" />
            <img className="md:h-14 w-[40px]" src={exchange} alt="crane" />
            <img className="md:h-14 w-[40px]" src={handshake1} alt="shipping" />
          </div>
        </div>
        </div>
      <div className="flex flex-wrap justify-center items-center space-x-5 space-y-5 w-full mb-4">
        <img src={dhl} alt="logo" className="h-64 w-64" />
        <img src={mitchell} alt="logo" className="h-24 w-64" />
        <img src={visa} alt="logo" className="h-20 w-auto" />
        <img src={i_m} alt="logo" className="h-40 w-auto" />
        <img src={ipay} alt="logo" className="h-24 w-auto" />
        <img src={uba} alt="logo" className="h-24 w-auto" />
        <img src={sbm} alt="logo" className="h-24 w-auto" />
        <img src={stanbic} alt="logo" className="h-24 w-auto" />
        <img src={mastercard} alt="logo" className="h-24 w-auto" />
        <img src={equity} alt="logo" className="h-24 w-auto" />
      </div>
    </div>
  );
};

export default HomePage;
